import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/react"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      moshiLogo: file(relativePath: { eq: "moshi_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      moshiConnectLogo: file(
        relativePath: { eq: "moshi_connect_thumb_2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      moshiReConnectLogo: file(
        relativePath: { eq: "moshi_re_connect_thumb_2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      jrockTamashiiBanner: file(
        relativePath: { eq: "2023-jrock-tamashii-banner.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='JRock Malaysia' />
      <Link to='/' css={css`width: 100%; display: flex; justify-content: center; margin-top: 1rem;`}>
        <img src={'/jrock-logo-clipped.png'} alt="Logo" css={css`
          width: 150px;
          margin: 0 auto;
        `} />
      </Link>
      <section
        css={css`
          max-width: 800px;
          margin: 0 auto;
          margin-top: 1rem;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        `}
      >
        <Link
          to='https://www.facebook.com/jrock.my'
          css={css`
            width: 600px;
            border-radius: 5px;
            padding: 5px;
            margin-bottom: 1rem;
            @media screen and (max-width: 500px) {
              margin-bottom: 3rem;
            }
          `}
        >
          <Img
            fluid={data.jrockTamashiiBanner.childImageSharp.fluid}
            css={css`
                margin: 0 auto;
                display: block;
                border-radius: 6px;
              `}
          />
        </Link>

        <Link
          to='/moshirock-re-connect'
          css={css`
            width: 600px;
            border-radius: 5px;
            padding: 5px;
            opacity: 0.5;
            @media screen and (max-width: 500px) {
              margin-bottom: 3rem;
            }
          `}
        >
          <Img
            fluid={data.moshiReConnectLogo.childImageSharp.fluid}
            css={css`
              margin: 0 auto;
              display: block;
            `}
          />
        </Link>
        <Link
          to='/moshirock-connect'
          css={css`
            width: 300px;
            border-radius: 5px;
            padding: 5px;
            opacity: 0.5;
            @media screen and (max-width: 500px) {
              margin-bottom: 3rem;
            }
          `}
        >
          <Img
            fluid={data.moshiConnectLogo.childImageSharp.fluid}
            css={css`
              margin: 0 auto;
              display: block;
            `}
          />
        </Link>

        <Link
          to='/moshirockfes'
          css={css`
            width: 300px;
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.5;
            @media screen and (max-width: 500px) {
              margin-bottom: 3rem;
            }
          `}
        >
          <Img
            fluid={data.moshiLogo.childImageSharp.fluid}
            css={css`
              width: 80%;
              margin: 0 auto;
              display: block;
            `}
          />
        </Link>
      </section>
    </Layout>
  )
}

export default IndexPage
